/***********************************************
Mixin
***********************************************/

@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-ms-border-radius: $radius;
}
@mixin border-radii($topleft, $topright, $bottomright, $bottomleft) {
	border-top-left-radius: $topleft;
	border-top-right-radius: $topright;
	border-bottom-right-radius: $bottomright;
	border-bottom-left-radius: $bottomleft;
	-webkit-border-top-left-radius: $topleft;
	-webkit-border-top-right-radius: $topright;
	-webkit-border-bottom-right-radius: $bottomright;
	-webkit-border-bottom-left-radius: $bottomleft;
	-moz-border-radius-topleft: $topleft;
	-moz-border-radius-topright: $topright;
	-moz-border-radius-bottomright: $bottomright;
	-moz-border-radius-bottomleft: $bottomleft;
}
@mixin transition ($transition) {
	transition: $transition;
	-webkit-transition: $transition;
	-ms-transition: $transition;
}
@mixin box-shadow ($inset, $color) {
	box-shadow: $inset, $color;
	-webkit-box-shadow: $inset, $color;
	-ms-box-shadow: $inset, $color;
}
@mixin transform ($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}
/*How to use
	.element{
		@include border(top, #000, 50px);
	}
*/

@mixin border($direction, $color, $size) {
	display: block;
	height: 0;
	width: 0;
	@if $direction=='top' {
		border-left: $size solid transparent;
		border-right: $size solid transparent;
		border-bottom: $size solid $color;
	}
	@else if $direction=='right' {
		border-top: $size solid transparent;
		border-bottom: $size solid transparent;
		border-left: $size solid $color;
	}
	@else if $direction=='bottom' {
		border-top: $size solid $color;
		border-right: $size solid transparent;
		border-left: $size solid transparent;
	}
	@else if $direction=='left' {
		border-top: $size solid transparent;
		border-right: $size solid $color;
		border-bottom: $size solid transparent;
	}
	@else if $direction=='top-left' {
		border-top: $size solid $color;
		border-right: $size solid transparent;
	}
	@else if $direction=='top-right' {
		border-top: $size solid $color;
		border-left: $size solid transparent;
	}
	@else if $direction=='bottom-left' {
		border-bottom: $size solid $color;
		border-right: $size solid transparent;
	}
	@else if $direction=='bottom-right' {
		border-bottom: $size solid $color;
		border-left: $size solid transparent;
	}
}
//Padding mixin
@mixin padding($top, $right, $bottom, $left) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}
//Margin mixin
@mixin margin($top, $right, $bottom, $left) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
}
@mixin ellipsis() {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-wrap: normal;
	width: 100%;
}
@mixin icon-styles() {
	position: relative;
	top: 1px;
	display: inline-block;
	font-family: 'Glyphicons Halflings';
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
