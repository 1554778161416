// Footer

/* Footer Top Start */
.footer {
	padding : 115px 0 100px;
	background-color: #304356;
	.f-top {
		border-bottom: 1px solid #29586b;
			padding: 0 0 35px;
	}
	.f-widget {
		h6 {
			font-family: $headings-font-family;
			font-size: $font-size-h6;
			line-height: 24px;
			color: $headings-color;
			margin: 0 0 28px;
			text-transform: uppercase;
		}
		p {
			font-size: $font-size-base;
			line-height: 22px;
			color: #a0b4c7;
		}
		ul {
			display: block;
			margin: 0;
			padding: 0;
			list-style-type: none;
			li {
				margin: 0;
				padding: 0;
				list-style-type: none;
				font-size: 12px;
				line-height: 34px;
				color: #a0b4c7;
				text-transform: uppercase;
				a {
					font-size: 12px;
					line-height: 34px;
					color: #a0b4c7;
					text-decoration: none;
				}
			}
		}
		.recent-news-block {
			.news-block {
				padding: 15px 0;
				border-bottom: 1px solid rgba(255,255,255,0.07);
				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					padding-bottom: 0;
					border: none;
				}
				a {
					color: $headings-color;
					text-decoration: none;
					font-size: 16px;
					line-height: 20px;
					font-weight: $font-weight-bold;
					font-family: 'Lato', sans-serif;
					display: block;
					margin: 0 0 5px;
				}
				p {
					i {
						margin-right: 10px;
					}
				}
			}
		}
	}
	.news-block {
		p {
				// color: #ffffff;
				color: rgba(255,255,255,0.6);
		}
	}
	.f-bottom {
		padding: 35px 0 0;
		text-align: center;
		p {
			font-size: 12px;
			line-height: 12px;
			color: #a0b4c7;
			margin: 0 0 20px;
		}
		ul {
			display: block;
			margin: 0;
			padding: 0;
			list-style-type: none;
			li {
				display: inline-block;
				margin: 0;
				padding: 0 3px 0 0;
				list-style-type: none;
				&:last-child {
						padding: 0;
				}
				a {
					background-color: #a1b5c8;
					display: block;
					@include border-radius(3px);
					margin: 0 3px;
					color: $headings-color;
					padding: 17px;
					position: relative;
					font-size: 15px;
					line-height: 15px;
					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						background-color: #111;
					}
					i {
						position: absolute;
						left: 50%;
						top: 50%;
						@include transform(translate(-50%,-50%));
					}
				}
			}
		}
	}
}
/* Footer Bottom End */
